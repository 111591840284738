<template>
  <b-tabs
    content-class="col-12 col-md-12 mt-12 mt-md-0"
    pills
    nav-wrapper-class="col-md-12 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ListIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Detay Listesi') }}</span>
      </template>
      <teklif-detay-list v-if="optionData.offer" :teklifData="optionData" />
    </b-tab>
    <!--/ general tab -->

    <!-- teklif onay tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ListIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Teklif Onay') }}</span>
      </template>
      <teklif-onay v-if="optionData.offer" :teklifData="optionData" />
    </b-tab>
    <!--/ teklif onay tab -->

  </b-tabs>
</template>

<script>
import AlertService from '@/common/alert.service'

import TeklifDetayList from './TeklifDetayList.vue'
import TeklifOnay from './TeklifOnay.vue'

export default {
  components: {
    TeklifDetayList,
    TeklifOnay,
  },
  data() {
    return {
      optionData: {
        offer: null,
        sepetOffer: null,
        satisPersonelInfo: null,
      },
    }
  },
  methods: {
    fetchTeklif() {
      const paramId = this.$route.params.id;
      if (paramId) {
        this.$store.dispatch('teklifCustomer/fetchTeklifSingle', { teklifId: paramId })
          .then(response => {
            if (response.statusCode === 200) {
              const dxSummaryFields = ['amtVat', 'amtWithDisc', 'amtTra', 'qty', 'amtWithDisc'];
              response.result.offer.uyumDetailItem.map(detay => {
                dxSummaryFields.forEach(summary => {
                  const summaryKey = `dx${summary}`;
                  detay[summaryKey] = Number(detay[summary].replace(',', '.'))
                })
                detay.stockName = detay.itemNameManual ?? detay.dcardName;
                if (detay.itemNameManual === '') detay.stockName = detay.dcardName;
                return detay;
              });
              this.optionData = response.result;
            } else {
              AlertService.error(this, response.message)
            }
          })
      }
    },
  },
  mounted() {
    this.fetchTeklif();
  },
}
</script>
