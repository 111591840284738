<template>
  <div>
    <b-card no-body class="p-2">
      <b-form>
        <b-row>
          <!-- Field: Bildiri -->
          <b-col cols="12" md="6" class="mb-3">
            <b-alert variant="danger" v-show="teklifData.sepetOffer.teklifDurumu === 0" show>
              <div class="alert-body">
                <span><strong>Önemli!</strong> Teklifi onaylamadan önce teklif dosyasını indirip kaşe ve imzalayıp yüklemelisiniz.</span>
              </div>
            </b-alert>
            <b-alert variant="primary" v-show="teklifData.sepetOffer.teklifDurumu === 1" show>
              <div class="alert-body">
                <span><strong>Önemli!</strong> Teklif onayladınız. En kısa sürede size geri dönüş yapılacaktır.</span>
              </div>
            </b-alert>
            <b-alert variant="success" v-show="teklifData.sepetOffer.teklifDurumu === 2" show>
              <div class="alert-body">
                <span><strong>Önemli!</strong> Teklif Onaylandı ve sipariş kaydı oluşturuldu.</span>
              </div>
            </b-alert>
            <b-alert variant="danger" v-show="teklifData.sepetOffer.teklifDurumu === 3" show>
              <div class="alert-body">
                <span><strong>Önemli!</strong> Teklif Red Edildi.</span>
              </div>
            </b-alert>
          </b-col>
          <!-- Field: Dosyasını İndir -->
          <b-col cols="12" md="6" class="mb-3">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              :disabled="isDownload"
              @click="downloadTeklifFile()"
            >
              <b-spinner small v-if="isDownload" />
              <feather-icon v-if="!isDownload" icon="DownloadIcon" class="mr-50" size="20" />
              Teklif Dosyasını İndir
            </b-button>
          </b-col>
        </b-row>
        
        <!-- Field: Durum Seç -->
        <b-row v-show="teklifData.sepetOffer.teklifDurumu === 0">
          <b-col cols="12" md="6" class="mb-3">
              <div class="demo-inline-spacing">
                <b-form-radio
                  v-model="onarRedDurum"
                  name="some-radios"
                  value="onay"
                >
                  Teklifi onaylamak istiyorum.
                </b-form-radio>
                <b-form-radio
                  v-model="onarRedDurum"
                  name="some-radios"
                  value="red"
                >
                  Teklifi reddetmek istiyorum.
                </b-form-radio>
              </div>
            </b-col>
        </b-row>

        <div v-show="onarRedDurum === 'onay'">
          <!-- Field: Dosyayı Yükle -->
          <b-row v-if="teklifData.sepetOffer.teklifDurumu === 0">
            <b-col cols="12" md="6" class="mb-3">
              <b-form-group
                label="Onaylanan Teklif Dosyasını Yükleyin"
                label-for="docTraCode"
              >
                <b-form-file
                  v-model="onyRequest.file"
                  id="file"
                  ref="file-input"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Field: Teklif Onayla Btn -->
          <b-row v-if="teklifData.sepetOffer.teklifDurumu === 0">
            <b-col cols="12" md="6">
              <b-button
                variant="warning"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                @click="onayTeklif()"
              >
                <feather-icon icon="SaveIcon" class="mr-50" size="16" />
                Teklifi Onayla
              </b-button>
            </b-col>
          </b-row>
        </div>
        <div v-show="onarRedDurum === 'red'">
          <!-- Field: Dosyayı Yükle -->
          <b-row v-if="teklifData.sepetOffer.teklifDurumu === 0">
            <b-col cols="12" md="6" class="mb-3">
              <b-form-group
                :label="$t('Açıklama')"
                label-for="teklifRedAciklama"
              >
                <b-form-textarea
                  id="teklifRedAciklama"
                  :placeholder="$t('Açıklama')"
                  v-model="teklifRedAciklama"
                  rows="4"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Field: Teklif Onayla Btn -->
          <b-row v-if="teklifData.sepetOffer.teklifDurumu === 0">
            <b-col cols="12" md="6">
              <b-button
                variant="danger"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                @click="redTeklif()"
              >
                <feather-icon icon="SaveIcon" class="mr-50" size="16" />
                Teklifi Reddet
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive';

// Alert
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  directives: {
    Ripple,
  },
  components: {},
  props: {
    teklifData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isDownload: false,
      teklifId: parseInt(this.$route.params.id, 10),
      onyRequest: {
        file: null,
      },
      onarRedDurum: null,
      teklifRedAciklama: null,
    };
  },
  methods: {
    downloadTeklifFile() {
      const paramId = this.teklifId;
      if (paramId) {
        this.isDownload = true;
        this.$store.dispatch('teklifCustomer/fetchTeklifFile', paramId)
          .then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response], { type: response.type }));
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'teklif');
            document.body.appendChild(fileLink);

            fileLink.click();
            this.isDownload = false;
          })
          .catch(() => {
            this.isDownload = false
          })
      }
    },

    onayTeklif() {
      if (this.onyRequest.file === null) {
        AlertService.error(this, this.$i18n.t('Teklif Dosyasını Yükleyin.'));
        return;
      }

      const onayForm = new FormData();
      onayForm.set('file', this.onyRequest.file);
      onayForm.set('teklifId', this.teklifId)
      this.$store.dispatch('teklifCustomer/onayTeklif', onayForm)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                });

                this.teklifData.sepetOffer = { teklifId: response.result.pId, teklifDurumu: response.result.teklifDurumu };
              } else {
                AlertService.error(this, response.message)
              }
            })
    },

    redTeklif() {
      const queryParams = {
        teklifDurumu: 3,
        teklifAciklama: this.teklifRedAciklama,
        teklifId: this.teklifId,
      };
      this.$store.dispatch('teklifCustomer/redTeklif', queryParams)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                });

                this.teklifData.sepetOffer = { teklifId: response.result.pId, teklifDurumu: response.result.teklifDurumu };
              } else {
                AlertService.error(this, response.message)
              }
            })
    },
  },
};
</script>

<style scoped>
</style>
